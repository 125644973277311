
import { Component, Prop, Vue } from 'vue-property-decorator';
import BendBestWorstFlowTable from './BendBestWorstFlowTable.vue';
import { BendPartDataEntry, RankingDataOfBendPart } from '@/models/Charts/chartsData';

export enum TabsBendRankingOfParts {
  BestFlow = 0,
  WorstFlow = 1,
  Bends = 2,
  ProductionOutput = 3,
}

@Component({
  components: { BendBestWorstFlowTable },
})
export default class BendRankingOfParts extends Vue {
  @Prop({ required: true })
  private data!: RankingDataOfBendPart | null;

  @Prop({ required: true, type: Boolean })
  private loading!: boolean;

  @Prop({ required: true, type: Boolean })
  private isFetchingForTheFirstTime!: boolean;

  private activeTab: TabsBendRankingOfParts = TabsBendRankingOfParts.BestFlow;

  private get coalescedDataOfBestFlow(): BendPartDataEntry[] {
    return this.getFlowData('bestFlowData');
  }

  private get coalescedDataOfWorstFlow(): BendPartDataEntry[] {
    return this.getFlowData('worstFlowData');
  }

  private getFlowData(flowType: 'bestFlowData' | 'worstFlowData'): BendPartDataEntry[] {
    return this.data?.[flowType] ?? [];
  }

  private get devices(): number {
    return this.getUniqueCount('deviceId');
  }

  private get differentPartsType(): number {
    return this.getUniqueCount('partName');
  }

  private getUniqueCount(field: 'deviceId' | 'partName'): number {
    let data: BendPartDataEntry[] = [];
    if (this.activeTab === TabsBendRankingOfParts.BestFlow) {
      data = this.coalescedDataOfBestFlow;
    } else if (this.activeTab === TabsBendRankingOfParts.WorstFlow) {
      data = this.coalescedDataOfWorstFlow;
    }

    const uniqueValues = new Set(data.map((item) => item[field]));
    return uniqueValues.size;
  }
}
