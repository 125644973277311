
import { Component, Prop, Vue } from 'vue-property-decorator';
import { decimalFormatter, formatPercentage } from '@/utils/number';
import TableWithActions2 from '../tableWithActions/TableWithActions2.vue';
import { BendPartDataEntry } from '@/models/Charts/chartsData';
import { TabsBendRankingOfParts } from './BendRankingOfParts.vue';

@Component({
  methods: {
    decimalFormatter,
    formatPercentage,
  },
  components: { TableWithActions2 },
})
export default class BendBestWorstFlowTable extends Vue {
  @Prop({ required: true })
  private data!: BendPartDataEntry[];

  @Prop({ required: true })
  private tab!: TabsBendRankingOfParts;

  private get labelFlowPercent() {
    return this.tab === TabsBendRankingOfParts.WorstFlow
      ? this.$t(`bend_ranking_of_parts.low_flow`)
      : this.$t(`bend_ranking_of_parts.high_flow`);
  }
}
